import { useState } from "react";

function Contact() {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');

  let params = subject || message || name  ? '?' : '';
  if (subject || name) params += `subject=${encodeURIComponent(`${name + ' - ' || '' }${subject}`)}`;
  if (message) params += `${subject ? '&' : ''}body=${encodeURIComponent(message)}`;

  return (
    <div className="container contact">
      <h2 className="main-title text-center">CONTACT</h2>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-4 mb-1">
            <input name="name" placeholder="Name" className="contact-input" value={name} onChange={({ target: { value }} ) => setName(value)} />
          </div>

          <div className="col-md-4 mb-1">
            <input name="email" placeholder="Email" className="contact-input" value={email} onChange={({ target: { value }} ) => setEmail(value)}/>
          </div>
          <div className="col-md-4 mb-1">
            <input
              name="subject"
              placeholder="Subject"
              className="contact-input"
              value={subject} onChange={({ target: { value }} ) => setSubject(value)}
            />
          </div>
        </div>
      </div>
      <br />
      <div className="col-md-12">
      <textarea
            name="message"
            placeholder="Message"
            className="contact-textarea"
            value={message} onChange={({ target: { value }} ) => setMessage(value)}
          />
      </div>

      <br></br>
      <div className="row">
        <div className="col-md-12">
          <a className="form-btn" href={`mailto:${email}${params}`}>Send Message</a>
        </div>
      </div>
    </div>
  );
}
export default Contact;
