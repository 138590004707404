import About from '../components/About';
import Services from '../components/Services';
import Contact from '../components/Contact';

function Main() {
  return (
    <>
      <main>
        <Services />
        <About image="./img/img1.png" scollName="training-scroll" title="TRAINING" description="NCE offers training for software development, specifically in fullstack development, typescript and javascript languages and AWS as a hosting solution." />
        <About image="./img/card1.png" scollName="about-scroll" description="NCE is a software company established in September 2021. We offer cutting edge software solutions for a variety of industries."  />
        <Contact />
      </main>
    </>
  );
}
export default Main;
