function About({ title = 'ABOUT', description, scollName, image}) {
  return (
    <>
      <div style={{marginTop:'15rem',width:'100%',height:'10px'}} className={scollName}></div>

      <div className="container about">
        <div className="row">
          <div className="col-md-6 text-center">
            <img alt="about" src={image} className="img-fluid" />
          </div>
          <div className="col-md-6">
            <h2 className="main-title about-h2">{title}</h2>
            <p className="main-p">
              {description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
